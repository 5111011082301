<template>
    <div>
        <form >

            <!-- paste from hq below here -->

        </form>
    </div>
</template>
<script>
    const api_root = 'insurance/verification'

    export default {
        name: 'InsuranceVerificationDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    // fields from hq
                },
                isEditable : 1,
                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
